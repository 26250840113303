import Vue from 'vue'
import i18n from "@/i18n";

export let TransDate = function (data){
  if(data == ''){
    return null
  }
  let time_str = new Date(data).toLocaleString('zh-tw');
  let date = time_str.split(' ')[0];
  let time = time_str.split(' ')[1];
  let year = date.split('/')[0]
  let month = date.split('/')[1]
  let day = date.split('/')[2]
  let trans_datetime = year+"年"+month+"月"+day+"日 "+time
  return trans_datetime;
}

Vue.prototype.TransDate = TransDate


export let getText = function (val, items) {
  let result = items.find(obj => {
    return obj.value == val;
  });
  if (result) {
    return result.text;
  }
  return val;
}

Vue.prototype.getText = getText


Vue.filter("Translate", value => i18n.t(value));
Vue.filter("getText", getText);
