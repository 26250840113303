<template>
  <div>
    <v-dialog v-model="popup_open" max-width="400px" @click:outside="close">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ message.title }}
        </v-card-title>

        <v-card-text>
          {{ message.text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="popup_open = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: (vm) => ({
    popup_open: false,
  }),

  props: {
    message: {
      type: Object,
      default: () => ({
        title: "",
        text: "",
      }),
    },
    dialog_message: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.popup_open = false;
    },
  },

  watch: {
    dialog_message() {
      this.popup_open = true;
    },
  },
};
</script>