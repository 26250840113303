import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        // {
        //   name: 'Dashboard',
        //   path: '',
        //   component: () => import('@/views/dashboard/Dashboard'),
        // },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'UrbanRenewalTables',
          path: 'tables/urban-renewal',
          component: () => import('@/views/dashboard/tables/UrbanRenewal'),
          meta: {
            requiresAuth: true
          }
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        {
          name: 'UrbanRenewalDetail',
          path: 'tables/urban-renewal/urban-renewal-detail',
          component: () => import('@/views/dashboard/pages/UrbanRenewalDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        {
          name: 'SignUp',
          path: 'signup',
          component: () => import('@/views/dashboard/pages/SignUp'),
          meta: {
            requiresAuth: false
          }
        },
        // Index
        {
          name: 'Index',
          path: '',
          component: () => import('@/views/dashboard/pages/Index'),
        },
        // Meeting
        {
          name: 'MeetingTables',
          path: 'tables/meeting',
          component: () => import('@/views/dashboard/tables/Meeting'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'MeetingDetail',
          path: 'tables/meeting/meeting-detail',
          component: () => import('@/views/dashboard/pages/MeetingDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'OwnerList',
          path: 'tables/urban-renewal/owner-list',
          component: () => import('@/views/dashboard/tables/OwnerList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'OwnerDetail',
          path: 'tables/urban-renewal/owner-detail',
          component: () => import('@/views/dashboard/pages/OwnerDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'IssueList',
          path: 'tables/meeting/issue-list',
          component: () => import('@/views/dashboard/tables/IssueList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'IssueDetail',
          path: 'tables/issue/issue-detail',
          component: () => import('@/views/dashboard/pages/IssueDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'ChooseMeeting',
          path: 'tables/issue',
          component: () => import('@/views/dashboard/pages/ChooseMeeting'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Voting',
          path: 'tables/issue/voting',
          component: () => import('@/views/dashboard/pages/Voting'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CommonPartList',
          path: 'tables/urban-renewal/commonpart-list',
          component: () => import('@/views/dashboard/tables/CommonPartList'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CommonPartDetail',
          path: 'tables/urban-renewal/commonpart-detail',
          component: () => import('@/views/dashboard/pages/CommonPartDetail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'UserMgr',
          path: 'tables/user_mgr',
          component: () => import('@/views/dashboard/tables/Users'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CompanyMgr',
          path: 'tables/company_mgr',
          component: () => import('@/views/dashboard/tables/Companys'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'CompanyProfile',
          path: 'tables/company_profile',
          component: () => import('@/views/dashboard/pages/CompanyProfile'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Shopping',
          path: 'pages/shopping',
          component: () => import('@/views/dashboard/pages/Shopping'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Order',
          path: 'tables/order',
          component: () => import('@/views/dashboard/tables/Orders'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'Task',
          path: 'tables/task',
          component: () => import('@/views/dashboard/tables/Tasks'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'SystemVariable',
          path: 'tables/system',
          component: () => import('@/views/dashboard/tables/SystemVariable'),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: 'SignIn',
          path: 'tables/meeting/sign-in',
          component: () => import('@/views/dashboard/pages/SignIn'),
          meta: {
            requiresAuth: true
          }
        },
      ],
    },
    {
      name: 'VoteRecord',
      path: '/VoteRecord',
      component: () => import('@/views/dashboard/pages/VoteRecord'),
    },
    {
      name: 'SignInRecord',
      path: '/signIn_record',
      component: () => import('@/views/dashboard/pages/SignInRecord'),
    },
  ],
})
